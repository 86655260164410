import css from '../../css/Public/Footage.module.css'
import Logo from '../../global/WisetechInterglobe Logo4.png'

const FootageV2 = () => {
    const content = (
        <>
            <section className={css.MainFootagePage}>
                <p>Wisetech Interglobe Limited 滙文智庫有限公司 ©2025</p>
            </section>
        </>
    )

    return content
}

export default FootageV2