import React, { useEffect, useState } from 'react';
import css from '../../../css/C0211/StockProductFunctionList.module.css'
import { IC0211StockFunctionPageProps, IC0211StockProductDetail } from '../../../Interface/interface';
import DatePicker from 'react-date-picker';
import axios from 'axios';
import { Cookies } from 'react-cookie';
const cookies = new Cookies()

const StockProductFunctionList: React.FC<IC0211StockFunctionPageProps> = ({
    ProductSKUID,
    setStockFunctionListShow,
    setShowAlert,
    setAlertType,
    setAlertMessage,
    setLoading
}) => {

    const [isFunctionMode, setFunctionMode] = useState<number>(0)
    const [isQTY, setQTY] = useState<number>(0)
    const [isShipFrom, setShipFrom] = useState<number>(-1)
    const [isShipTo, setShipTo] = useState<number>(-1)
    const [isRemark, setRemark] = useState<string>('')

    const [isDateTime, setDateTime] = useState<string | null>('')

    const [isProductDetail, setProductDetail] = useState<IC0211StockProductDetail>()

    useEffect(() => {
        if (ProductSKUID !== -1) {
            getStockInOutRecord()
        }
    }, [])

    useEffect(() => {
        if (isQTY < 0) {
            setQTY(0)
        }
    }, [isQTY])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        switch (name) {
            case 'ShipFrom':
                setShipFrom(parseInt(value))
                break;
            case 'ShipTo':
                setShipTo(parseInt(value))
                break;
            default:
                break;
        }
    };

    const getStockInOutRecord = async () => {
        const token = cookies.get('token')
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/GetProductWarehouseList`, {
                Product_Sku_ID: ProductSKUID,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (res.status === 200) {
                setProductDetail(res.data)
                console.log(res.data)
            }

        } catch (error) {
            console.error(error)
        } finally {
            // setLoading(false)
        }
    }

    const resetAllInput = () => {
        setQTY(0)
        setShipFrom(-1)
        setShipTo(-1)
        setRemark('')
        setDateTime(null)
    }

    const handleSendQuickChangeStock = async () => {

        if (isDateTime === null) {
            setAlertType('Error')
            setAlertMessage('請選擇日期')
            setShowAlert(true)
            return
        }

        if (isShipFrom === -1 || isShipTo === -1) {
            setAlertType('Error')
            setAlertMessage('請選擇倉庫')
            setShowAlert(true)
            return
        }

        if (isShipFrom === isShipTo) {
            setAlertType('Error')
            setAlertMessage('轉出倉庫與轉入倉庫不可相同')
            setShowAlert(true)
            return
        }

        if (isQTY === 0) {
            setAlertType('Error')
            setAlertMessage('請輸入轉移量')
            setShowAlert(true)
            return
        }

        const postData = {
            Product_Sku_ID: ProductSKUID,
            Order_Date: isDateTime + " 00:00:00",
            Order_Type: "switch",
            Order_Ship_From: isShipFrom,
            Order_Ship_To: isShipTo,
            Order_Amount: isQTY,
            Order_Remark: isRemark
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/QuickInsertOrderProductDetail`, postData, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                alert('轉倉成功')
                setAlertType('Success')
                setAlertMessage('轉倉成功')
                setShowAlert(true)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            // setFunctionMode(0)
        }
    }

    const handleSendScrapStock = async () => {
        const postData = {
            Product_Sku_ID: ProductSKUID,
            Order_Date: isDateTime + " 00:00:00",
            Order_Type: "scrap",
            Order_Ship_From: isShipFrom,
            Order_Amount: isQTY,
            Order_Remark: isRemark
        }

        console.log(postData)

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/QuickInsertOrderProductDetail`, postData, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                console.log(res.data)
                alert('成功註銷')
            }
        } catch (error) {
            console.error(error)
        } finally {
            // setFunctionMode(0)
        }
    }

    const handleSendStockOutStock = async () => {
        const postData = {
            Product_Sku_ID: ProductSKUID,
            Order_Date: isDateTime + " 00:00:00",
            Order_Type: "stock out",
            Order_Ship_From: isShipFrom,
            Order_Amount: isQTY,
            Order_Remark: isRemark
        }

        console.log(postData)

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/QuickInsertOrderProductDetail`, postData, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                console.log(res.data)
                alert('成功出倉')
            }
        } catch (error) {
            console.error(error)
        } finally {
            // setFunctionMode(0)
        }
    }

    const handleSendStockInStock = async () => {
        const postData = {
            Product_Sku_ID: ProductSKUID,
            Order_Date: isDateTime + " 00:00:00",
            Order_Type: "stock in",
            Order_Ship_To: isShipTo,
            Order_Amount: isQTY,
            Order_Remark: isRemark
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/QuickInsertOrderProductDetail`, postData, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                console.log(res.data)
                alert('成功入倉')
            }
        } catch (error) {
            console.error(error)
        } finally {
            // setFunctionMode(0)
        }
    }


    const handleDateChange = (value: Date | null) => {
        if (value !== null) {
            const enteredYear = value.getFullYear();
            if (enteredYear < 1800 || enteredYear > 2100) {
                return;
            }

            const day = String(value.getDate()).padStart(2, '0'); // Pad the day to always be two digits
            const month = String(value.getMonth() + 1).padStart(2, '0'); // Pad the month (January is 0)
            const year = String(value.getFullYear());

            const formattedDate = `${year}-${month}-${day}`;

            setDateTime(formattedDate)
        }
    };

    const content = (
        <>
            <section className={css.MainSection}>
                {isFunctionMode === 0 && (
                    <>
                        <div className={css.BackgroundDiv}>
                            <div className={css.HeaderDiv}>
                                <p>查看貨品資料</p>
                                <i className="fi fi-br-times-hexagon" onClick={() => setStockFunctionListShow(false)} />
                            </div>

                            <div className={css.ProductFunctionListDiv}>
                                <div className={css.EachFunctionDiv} style={{ backgroundColor: '#EBEAEA' }} onClick={() => { setFunctionMode(1); resetAllInput(); }}>
                                    <p>快速轉倉</p>
                                    <i className="fi fi-br-insert-alt" />
                                </div>

                                <div className={css.EachFunctionDiv} style={{ backgroundColor: '#EBEAEA' }} onClick={() => { setFunctionMode(2); resetAllInput(); }}>
                                    <p>註銷產品</p>
                                    <i className="fi fi-br-insert-alt" />
                                </div>

                                <div className={css.EachFunctionDiv} style={{ backgroundColor: '#FFDDDD' }} onClick={() => { setFunctionMode(3); resetAllInput(); }}>
                                    <p>快速出倉</p>
                                    <i className="fi fi-br-insert-alt" />
                                </div>

                                <div className={css.EachFunctionDiv} style={{ backgroundColor: '#ECFFDD' }} onClick={() => { setFunctionMode(4); resetAllInput(); }}>
                                    <p>快速入倉</p>
                                    <i className="fi fi-br-insert-alt" />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {isFunctionMode === 1 && (
                    <>
                        <div className={css.BackgroundDiv}>
                            <div className={css.HeaderDiv}>
                                <p>其它 - 快速轉倉</p>
                                <i className="fi fi-bs-redo-alt" onClick={() => setFunctionMode(0)} />
                            </div>

                            <div className={css.ProductFunctionListDiv}>
                                <div className={css.QuickChangeStockDiv} style={{ backgroundColor: '#EBEAEA' }}>
                                    <div className={css.RequriedFieldDiv}>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachInputDiv}>
                                                <label>日期</label>
                                                <DatePicker
                                                    value={isDateTime ? new Date(isDateTime) : null}
                                                    onChange={(value) => handleDateChange(value as Date | null)}
                                                    format="dd-MM-yyyy"
                                                    className={css.DatePicker}
                                                    clearIcon={null}
                                                    yearPlaceholder="yyyy"
                                                    monthPlaceholder="mm"
                                                    dayPlaceholder='dd'
                                                    autoFocus={false}
                                                />
                                            </div>

                                            <div className={css.EachProductDetailDiv}>
                                                <label>轉移量</label>
                                                <input
                                                    type="number"
                                                    value={isQTY}
                                                    onChange={(e) => setQTY(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>

                                        <div className={css.EachGroupInputDiv} >
                                            <div className={css.EachProductDetailDiv} style={{ width: '160px' }}>
                                                <label>倉庫(轉出)</label>
                                                <select name="ShipFrom" value={isShipFrom} onChange={handleInputChange}>
                                                    <option value={-1}></option>
                                                    {isProductDetail?.ProductWarehouseList.map((item, index) => (
                                                        <option key={index} value={item.Product_Warehouse_ID}>{item.Product_Warehouse_Location}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className={css.EachProductDetailDiv} style={{ width: '160px' }}>
                                                <label>倉庫(轉入)</label>
                                                <select name="ShipTo" value={isShipTo} onChange={handleInputChange}>
                                                    <option value={-1}></option>
                                                    {isProductDetail?.ProductWarehouseList.map((item, index) => (
                                                        <option key={index} value={item.Product_Warehouse_ID}>{item.Product_Warehouse_Location}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachProductDetailDiv}>
                                                <label>備註</label>
                                                <input
                                                    type="text"
                                                    value={isRemark}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={css.ButtonDiv}>
                                        <button onClick={() => handleSendQuickChangeStock()}>確認</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )}

                {isFunctionMode === 2 && (
                    <>
                        <div className={css.BackgroundDiv}>
                            <div className={css.HeaderDiv}>
                                <p>其它 - 註銷產品</p>
                                <i className="fi fi-bs-redo-alt" onClick={() => setFunctionMode(0)} />
                            </div>

                            <div className={css.ProductFunctionListDiv}>
                                <div className={css.QuickChangeStockDiv} style={{ backgroundColor: '#EBEAEA' }}>
                                    <div className={css.RequriedFieldDiv}>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachInputDiv}>
                                                <label>日期</label>
                                                <DatePicker
                                                    value={isDateTime ? new Date(isDateTime) : null}
                                                    onChange={(value) => handleDateChange(value as Date | null)}
                                                    format="dd-MM-yyyy"
                                                    className={css.DatePicker}
                                                    clearIcon={null}
                                                    yearPlaceholder="yyyy"
                                                    monthPlaceholder="mm"
                                                    dayPlaceholder='dd'
                                                    autoFocus={false}
                                                />
                                            </div>

                                            <div className={css.EachProductDetailDiv} style={{ width: '160px' }}>
                                                <label>倉庫</label>
                                                <select name="ShipFrom" value={isShipFrom} onChange={handleInputChange}>
                                                    <option value={-1}></option>
                                                    {isProductDetail?.ProductWarehouseList.map((item, index) => (
                                                        <option key={index} value={item.Product_Warehouse_ID}>{item.Product_Warehouse_Location}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachProductDetailDiv}>
                                                <label>註銷量</label>
                                                <input
                                                    type="number"
                                                    value={isQTY}
                                                    onChange={(e) => setQTY(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachProductDetailDiv}>
                                                <label>備註</label>
                                                <textarea name="remark" cols={70} rows={5} value={isRemark} onChange={(e) => setRemark(e.target.value)} className={css.remarkinput} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={css.ButtonDiv}>
                                        <button onClick={() => handleSendScrapStock()}>確認</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )}

                {isFunctionMode === 3 && (
                    <>
                        <div className={css.BackgroundDiv}>
                            <div className={css.HeaderDiv}>
                                <p>其它 - 快速出倉</p>
                                <i className="fi fi-bs-redo-alt" onClick={() => setFunctionMode(0)} />
                            </div>

                            <div className={css.ProductFunctionListDiv}>
                                <div className={css.QuickChangeStockDiv} style={{ backgroundColor: '#FFDDDD' }}>
                                    <div className={css.RequriedFieldDiv}>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachInputDiv}>
                                                <label>日期</label>
                                                <DatePicker
                                                    value={isDateTime ? new Date(isDateTime) : null}
                                                    onChange={(value) => handleDateChange(value as Date | null)}
                                                    format="dd-MM-yyyy"
                                                    className={css.DatePicker}
                                                    clearIcon={null}
                                                    yearPlaceholder="yyyy"
                                                    monthPlaceholder="mm"
                                                    dayPlaceholder='dd'
                                                    autoFocus={false}
                                                />
                                            </div>

                                            <div className={css.EachProductDetailDiv} style={{ width: '160px' }}>
                                                <label>倉庫(轉出)</label>
                                                <select name="ShipFrom" value={isShipFrom} onChange={handleInputChange}>
                                                    <option value={-1}></option>
                                                    {isProductDetail?.ProductWarehouseList.map((item, index) => (
                                                        <option key={index} value={item.Product_Warehouse_ID}>{item.Product_Warehouse_Location}</option>
                                                    ))}
                                                </select>
                                            </div>

                                        </div>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachProductDetailDiv} >
                                                <label>倉存量</label>
                                                <input
                                                    type="number"
                                                    value={isQTY}
                                                    onChange={(e) => setQTY(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachProductDetailDiv}>
                                                <label>備註</label>
                                                <textarea name="remark" cols={70} rows={5} value={isRemark} onChange={(e) => setRemark(e.target.value)} className={css.remarkinput} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={css.ButtonDiv}>
                                        <button onClick={() => handleSendStockOutStock()}>確認</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )}

                {isFunctionMode === 4 && (
                    <>
                        <div className={css.BackgroundDiv}>
                            <div className={css.HeaderDiv}>
                                <p>其它 - 快速入倉</p>
                                <i className="fi fi-bs-redo-alt" onClick={() => setFunctionMode(0)} />
                            </div>

                            <div className={css.ProductFunctionListDiv}>
                                <div className={css.QuickChangeStockDiv} style={{ backgroundColor: '#ECFFDD' }}>
                                    <div className={css.RequriedFieldDiv}>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachInputDiv}>
                                                <label>日期</label>
                                                <DatePicker
                                                    value={isDateTime ? new Date(isDateTime) : null}
                                                    onChange={(value) => handleDateChange(value as Date | null)}
                                                    format="dd-MM-yyyy"
                                                    className={css.DatePicker}
                                                    clearIcon={null}
                                                    yearPlaceholder="yyyy"
                                                    monthPlaceholder="mm"
                                                    dayPlaceholder='dd'
                                                    autoFocus={false}
                                                />
                                            </div>

                                            <div className={css.EachProductDetailDiv}>
                                                <label>倉庫(轉入)</label>
                                                <select name="ShipTo" value={isShipTo} onChange={handleInputChange}>
                                                    <option value={-1}></option>
                                                    {isProductDetail?.ProductWarehouseList.map((item, index) => (
                                                        <option key={index} value={item.Product_Warehouse_ID}>{item.Product_Warehouse_Location}</option>
                                                    ))}
                                                </select>
                                            </div>


                                        </div>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachProductDetailDiv}>
                                                <label>倉存量</label>
                                                <input
                                                    type="number"
                                                    value={isQTY}
                                                    onChange={(e) => setQTY(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>

                                        <div className={css.EachGroupInputDiv}>
                                            <div className={css.EachProductDetailDiv}>
                                                <label>備註</label>
                                                <textarea name="remark" cols={70} rows={5} value={isRemark} onChange={(e) => setRemark(e.target.value)} className={css.remarkinput} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className={css.ButtonDiv}>
                                        <button onClick={() => handleSendStockInStock()}>確認</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )}

            </section>
        </>
    )

    return content
}

export default StockProductFunctionList;