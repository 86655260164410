import { Helmet, HelmetProvider } from "react-helmet-async" 
//Pages
import { Outlet } from "react-router-dom"
import { useContext, useEffect } from "react";
import { FetchSystemData } from "../../Interface/Const";
import { SystemContext } from "../../Class/SystemPara";

import NavBar from '../../Components/Public/NavBar'
import Footage from '../../Components/Public/Footage'

const PublicLayoutV2 = () => {

    const systemContext = useContext(SystemContext);
    
    useEffect(() => {
        FetchSystemData(systemContext)
    }, []);
    
    
    const content = (
        <body>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                        <title>Wisetech Interglobe</title>
                    </Helmet>
                    <NavBar />
                    <Outlet />
                    <Footage />  
                </div>
            </HelmetProvider>
        </body>
        
    )
    return content
}

export default PublicLayoutV2