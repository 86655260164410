import css from "../../css/Public/ProductPage.module.css"

import dataAnalytic from '../../global/data-analytic.png'
import excel from '../../global/excel.png'
import responsive from '../../global/responsive-design.png'
import layers from '../../global/layers.png'
import { Background } from "react-parallax"
import { Add } from "react-ionicons"
import { useEffect, useState } from "react"

interface AddOn {
    ProductName: string;
    ProgramDesc: string[];
    Price: string;
}

interface Product {
    ProgramName: string;
    ProgramDesc: string[];
    Price: string;
    AddOn?: AddOn[];
}

interface ProgramItem {
    name: string;
    image: string;
    backgroundcolor: string;
    description: string;
    Discount: string;
    Product: Product[];
}

const ProgramList: ProgramItem[] = [
    {
        name: '數據分析服務',
        image: dataAnalytic,
        backgroundcolor: '#FFFFFF',
        description: '透過數據分析，將原始資料轉換為可視化報表，幫助企業快速了解業務狀況，做出明智決策。',
        Discount: "送首年維護費用",
        Product: [
            {
                ProgramName: "數據視覺化",
                ProgramDesc: [
                    "將數據轉化為可視化圖表，幫助企業快速了解業務狀況",
                    "提供多種圖表選擇，滿足不同需求"
                ],
                Price: "HKD ?,000 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "數據分析",
                ProgramDesc: [
                    "提供數據分析服務，幫助企業從數據中獲取洞察",
                    "支持多種數據格式和來源"
                ],
                Price: "HKD ?,000 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "數據清理",
                ProgramDesc: [
                    "提供數據清理服務，幫助企業處理不完整或不準確的數據",
                    "支持多種數據格式和來源"
                ],
                Price: "HKD ?,000 起 \n (維護費用為開發費用的 10%)",
            }
        ]
    },
    {
        name: 'Excel 數據處理',
        image: excel,
        backgroundcolor: '#F5F5F5',
        description: '透過數據分析，將原始資料轉換為可視化報表，幫助企業快速了解業務狀況，做出明智決策。',
        Discount: "送首年維護費用",
        Product: [
            {
                ProgramName: "Excel On-line",
                ProgramDesc: [
                    "使用雲端伺服器, 實現全實時及多機同步資訊的各種使用方法",
                ],
                Price: "HKD 3,000 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "One Click Done 小程式工具",
                ProgramDesc: [
                    "簡單操作及便宜價錢, 就能訂製各種方便功能的小程式工具",
                ],
                Price: "HKD 800 起 \n (維護費用為開發費用的 10%)",
            }
        ]
    },
    {
        name: 'Web-System',
        image: responsive,
        backgroundcolor: '#FFFFFF',
        description: '提供響應式設計服務，確保網站在各種設備上均能良好顯示，提升用戶體驗。',
        Discount: "送首年維護費用",
        Product: [
            {
                ProgramName: "人事管理-員工資料庫",
                ProgramDesc: [
                    "輕鬆管理員工資料、人手調配",
                    "評核、升遷或降職全部記錄清楚"
                ],
                Price: "HKD 8,000 起 \n (維護費用為開發費用的 10%)",
                AddOn: [
                    {
                        ProductName: "薪酬計算系統 (附加)",
                        ProgramDesc: [
                            "簡單幾個資料輸入就自動計算所有員工薪酬",
                            "年假、病假、遲到時間計算並自動調整薪酬",
                            "津貼及強積金自動計算並自動調整薪酬",
                            "自動生成報稅報告",
                        ],
                        Price: "HKD 18,000 起 (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "佣金計算系統 (附加)",
                        ProgramDesc: [
                            "簡單幾個資料輸入就自動計算所有員工佣金- 任何複雜的計算方法都能自動演算",
                        ],
                        Price: "HKD 18,000 起 (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "奬金計算系統 (附加)",
                        ProgramDesc: [
                            "自動整合資料並計算獎金- 任何複雜的奬金計算方法都能自動演算",
                        ],
                        Price: "HKD 8,000 起 (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "連結會計系統 (附加)",
                        ProgramDesc: [
                            "電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入",
                            "自動生成每月/每季/每年採購報告及成本控制報告",
                        ],
                        Price: "HKD 8,000 起 (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "應用程式-假期申報 (附加)",
                        ProgramDesc: [
                            "假期 / 例假 即時申報, 總部即時收到並審批",
                        ],
                        Price: "HKD 12,000 起 (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "應用程式-上下班記錄 (附加)",
                        ProgramDesc: [
                            "自動打卡, 完全代替工卡, 避免人手錯誤, 並自動電子化作計糧處理",
                        ],
                        Price: "HKD 12,000 起 (維護費用為開發費用的 10%)",
                    },
                ]
            },
            {
                ProgramName: "採購系統",
                ProgramDesc: [
                    "連結供應商數據庫，輕鬆管理及開採購單",
                    "簡單幾個資料輸入就可以自動整合報價單",
                ],
                Price: "HKD 18,000 起 \n (維護費用為開發費用的 10%)",
                AddOn: [
                    {
                        ProductName: "流程管制系統 (附加)",
                        ProgramDesc: [
                            "所有採購單據狀況一目了然，令你更妥善安排行動",
                        ],
                        Price: "HKD 8,000 起 \n (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "流程審批系統 (附加)",
                        ProgramDesc: [
                            "電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入",
                            "自動生成每月/每季/每年銷售資料報告及盈利分析報告",
                            "將所需資料全面電子化, 往後可以製作各種報表 (例如: 收支表, 追數表, 貨存記錄表等等)"
                        ],
                        Price: "HKD 8,000 起 \n (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "連結會計系統 (附加)",
                        ProgramDesc: [
                            "電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入",
                            "自動生成每月/每季/每年採購報告及成本控制報告"
                        ],
                        Price: "HKD 8,000 起 \n (維護費用為開發費用的 10%)",
                    }
                ]
            },
            {
                ProgramName: "庫存系統",
                ProgramDesc: [
                    "輕鬆管理貨品數量資料庫，即時庫存一目了然",
                    "自動入貨、出貨資料輸入 (需配合特定硬件)",
                    "輕鬆庫存盤點整合功能"
                ],
                Price: "HKD 18,000 起 \n (維護費用為開發費用的 10%)",
                AddOn: [
                    {
                        ProductName: "應用程式-庫存系統(附加)",
                        ProgramDesc: [
                            "貨存出入即時記錄, 即時更新, 隨時隨地查詢貨存資料",
                        ],
                        Price: "HKD 15,000 起 \n (維護費用為開發費用的 10%)",
                    },
                ]
            },
            {
                ProgramName: "發票及報價單系統",
                ProgramDesc: [
                    "開單自動化，簡單幾個資料輸入就可以自動生成發票或報價單",
                    "連結客戶數據庫，輕鬆管理及開單",
                    "連結貨品數據庫，銷售價錢、成本價全部一目了然",
                    "合約自動生成功能"
                ],
                Price: "HKD 18,000 起 \n (維護費用為開發費用的 10%)",
                AddOn: [
                    {
                        ProductName: "流程管制系統 (附加)",
                        ProgramDesc: [
                            "所有單據狀況一目了然，令你更妥善安排行動",
                        ],
                        Price: "HKD 8,000 起 \n (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "流程審批系統 (附加)",
                        ProgramDesc: [
                            "流程審批功能，令管理更輕鬆",
                        ],
                        Price: "HKD 8,000 起 \n (維護費用為開發費用的 10%)",
                    },
                    {
                        ProductName: "連結會計系統 (附加)",
                        ProgramDesc: [
                            "電腦自動將所需資料輸入會計系統(如適用)，不用再人手輸入",
                            "自動生成每月/每季/每年銷售資料報告及盈利分析報告",
                            "將所需資料全面電子化, 往後可以製作各種報表 (例如: 收支表, 追數表, 貨存記錄表等等)",
                        ],
                        Price: "HKD 8,000 起 \n (維護費用為開發費用的 10%)",
                    }
                ]

            }
        ]
    },
    {
        name: 'One Click Done Tool',
        image: layers,
        backgroundcolor: '#FFFFFF',
        description: '一個按鍵，瞬間將資料轉化為公司所需的行政／會計報表或匯入格式，帶來100%的資料準確性。',
        Discount: "送首年維護費用",
        Product: [
            {
                ProgramName: "資料整合工具",
                ProgramDesc: [
                    "一個按鍵，瞬間將其他格式資料轉換成你公司格式",
                    "瞬間將不同系統的資料整合並處理好"
                ],
                Price: "HKD 1,200 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "電腦檔案整理工具",
                ProgramDesc: [
                    "一個按鍵，瞬間將一整個文件夾的資料處理 (重新命名或分別移動到其他不同文件夾)",
                ],
                Price: "HKD 1,200 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "自動發電郵工具",
                ProgramDesc: [
                    "一個按鍵，瞬間將數千封電郵以不同收件人及自訂不同標題及內容發出",
                ],
                Price: "HKD 2,000 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "發單工具",
                ProgramDesc: [
                    "一個按鍵，即是將所需資料抽取並輸出發票或任何格式 (客戶需要自行設計輸出格式)",
                    "因應你獨特的需求, 製作適合你的發單程式",
                    "將所需資料全面電子化, 往後可以製作各種報表 (例如: 收支表, 追數表, 貨存記錄表等等)"
                ],
                Price: "HKD 6,000 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "支票格式自動化",
                ProgramDesc: [
                    "一個按鍵，自動轉換成不同的銀行格式支票作列印，並自動輸出銀碼的英文或中文字句",
                ],
                Price: "HKD 600 起 \n (維護費用為開發費用的 10%)",
            },
            {
                ProgramName: "信封格式自動化",
                ProgramDesc: [
                    "一個按鍵，根據資料庫的資料瞬間排好信封格式作列印",
                ],
                Price: "HKD 600 起 \n (維護費用為開發費用的 10%)",
            },
        ]
    }
]


const ProductPage = () => {

    const [isAddOnProductType, setAddOneProductType] = useState('');
    const [isAddOnProduct, setAddOneProduct] = useState('');

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.ProductDiv}>
                    <h1>產品介紹</h1>
                    <hr className={css.Line} />
                </div>

                {isAddOnProduct.length > 0 ? (
                    <div className={css.ProductListDiv} >
                        {ProgramList.filter((item) => item.name === isAddOnProductType).map((item, index) => (
                            <div className={css.EachProductTypeDiv} style={{ backgroundColor: item.backgroundcolor }} key={index}>
                                <div className={css.EachProductBaseInfo}>
                                    <div className={css.EachProductBaseImage}>
                                        <img src={item.image} alt="Product" />
                                    </div>
                                    <div className={css.EachProductList}>
                                        {item.Product.filter(product => product.ProgramName === isAddOnProduct).map((product, index) => (
                                            <div key={index} style={{ position: 'relative' }}>
                                                <div className={css.BackButtonDiv}>
                                                    <button className={css.BackButton} onClick={() => { setAddOneProduct(''); setAddOneProductType('') }}>
                                                        返回
                                                    </button>
                                                </div>
                                                <h1>{product.ProgramName} - 附加功能</h1>
                                                <hr className={css.Line} style={{ marginTop: '10px', marginBottom: '10px' }} />
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}>
                                                    {product.AddOn?.map((addon, idx) => (
                                                        <div className={css.EachProductInfoDiv} key={idx}>
                                                            <h2>{addon.ProductName}</h2>
                                                            <hr className={css.Line} />
                                                            <div className={css.EachProductDescDiv}>
                                                                <ul>
                                                                    {addon.ProgramDesc.map((desc, descIdx) => (
                                                                        <li key={descIdx}>{desc}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>

                                                            <div className={css.PriceDiv}>
                                                                <p>{addon.Price}</p>
                                                            </div>

                                                            <div className={css.ControlButtonDiv}>
                                                                <button className={css.ControlButton}>立即報價</button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={css.ProductListDiv} >
                        {ProgramList.map((item, index) => (
                            <div className={css.EachProductTypeDiv} style={{ backgroundColor: item.backgroundcolor }} key={index}>
                                <div className={css.EachProductBaseInfo}>
                                    <div className={css.EachProductBaseImage}>
                                        <div className={css.ProductTitleDiv}>
                                            <h1>{item.name}</h1>
                                        </div>
                                        <img src={item.image} alt="Product" />
                                    </div>
                                    <div className={css.EachProductList}>
                                        {item.Product.map((product, index) => (
                                            <div className={css.EachProductInfoDiv}>
                                                <h1>{product.ProgramName}</h1>
                                                <hr className={css.Line} />
                                                <div className={css.EachProductDescDiv}>
                                                    <ul>
                                                        {product.ProgramDesc.map((desc, index) => (
                                                            <li key={index}>{desc}</li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className={css.PriceDiv}>
                                                    <p>{product.Price}</p>
                                                </div>

                                                <div className={css.ControlButtonDiv}>
                                                    <button className={css.ControlButton}>立即報價</button>
                                                    {(product.AddOn?.length ?? 0) > 0 ? <button className={css.ControlButtonAddOn} onClick={() => { setAddOneProduct(product.ProgramName); setAddOneProductType(item.name); }} >查看付加功能</button> : null}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}


            </section>
        </>
    )

    return content
}

export default ProductPage