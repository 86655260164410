import { useContext, useEffect, useState } from 'react';
import css from '../../../css/C0211/UserList.module.css'
import { c0211UserListProps, IC0211GetUserListResponse, IC0211UserList } from '../../../Interface/interface';
import axios from 'axios';
import { CreateOutline } from 'react-ionicons';
import { Cookies } from 'react-cookie';
import DeafultLoadingBar from '../../LoadingBar/DefaultLoadingBar';
import AlertMessage from '../../LoadingBar/AlertMessage';
import { PreLoadingContext } from '../../../Class/C0211PreLoadingData';
const cookies = new Cookies()


const C0211UserList: React.FC<c0211UserListProps> = ({ setUserID }) => {
    const preLoadDataContext = useContext(PreLoadingContext);

    const [data, setData] = useState<IC0211UserList[]>(preLoadDataContext.preLoadData.isUserList);
    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');

    useEffect(() => {
        if (preLoadDataContext.preLoadData.isUserList.length === 0) {
            setLoading(true);
            GetFunctionList()
        }
    }, [])

    async function GetFunctionList() {
        const token = cookies.get('token')
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/user/GetUserList`, {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (res.status === 200) {
                setData(res.data.User)
            }

        } catch (error) {
            console.log(error)
        } finally {
            console.log('Get User List Done')
            setLoading(false)
        }
    }

    // Employee type
    const employmentType = (type?: string) => {
        switch (type) {
            case "LFT":
                return "Local Full Time"
            case "LPT":
                return "Local Part Time"
            case "LFL":
                return "Local Freelance"
            case "OFL":
                return "Overseas Freelance"
            case "OFT":
                return "Overseas Full Time"
            case "OPL":
                return "Overseas Part Time"
            default:
                return 'Unknown';
        }
    }

    // Seach User
    function SearchUserFc() {
        const input = document.getElementById("SearchUserTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("UserListTable") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdStaffID = tr[i].getElementsByTagName("td")[0];
            const tdUsername = tr[i].getElementsByTagName("td")[1];
            if (tdStaffID || tdUsername) {
                const txtStaffIDValue = tdStaffID.textContent || tdStaffID.innerText;
                const txtUsernameValue = tdUsername.textContent || tdUsername.innerText;
                // Check if either Staff ID or Username matches the filter
                if (txtStaffIDValue.toUpperCase().indexOf(filter) > -1 ||
                    txtUsernameValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    const content = (
        <>

            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar isACID='C0211' />}

            <section className={css.TopSection}>
                {/* <div className={css.ControlBar}>
                    <div className={css.TopDiv}>
                        <div>
                            <p className={css.Title}>Employee List</p>
                        </div>

                        <div className={css.AddUserBtnDiv}>
                            <button className={css.BottomBtn} onClick={() => setUserID(-1)}>
                                <i className="fi fi-rs-user-add" />
                                Add New User
                            </button>
                        </div>
                    </div>

                    <input type="text" id="SearchUserTxt" onKeyUp={SearchUserFc} className={css.SeachUser} placeholder="Search for staff id.." ></input>
                </div> */}

                <div className={css.AddUserBtnDiv}>
                    <button className={css.BottomBtn} onClick={() => setUserID(-1)}>
                        <i className="fi fi-rs-user-add" />
                        Add New User
                    </button>
                </div>

                <div className={css.ListUser}>
                    <table className={css.UserTable} id="UserListTable">
                        <tr>
                            <th>Staff ID</th>
                            <th>Username</th>
                            <th>Department</th>
                            <th>Job Title</th>
                            <th>Employment Type</th>
                            <th>Status</th>
                            <th>Nationality</th>
                            <th>Edit</th>
                        </tr>
                        {data.map(user => (
                            <tr key={user.user_id}> {/* Using user_id as the key */}
                                <td>{user.login_id}</td>
                                <td>{user.user_name}</td>
                                <td>{user.department}</td>
                                <td>{user.job_title}</td>
                                <td>{employmentType(user.employment_type)}</td>
                                <td>{user.user_status}</td>
                                <td>{user.nationality}</td>
                                <td className={css.EditUserBtn}>
                                    <CreateOutline
                                        color={'#032654'}
                                        height="30px"
                                        width="30px"
                                        onClick={() => setUserID(user.user_id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </section>
        </>
    )

    return content
}

export default C0211UserList