import css from "../../css/Public/AboutUs.module.css"

import logo from '../../global/WisetechInterglobe Logo4.png'
import leadership from '../../global/leadership.png'

const AboutUsPage = () => {
    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.AboutUsDiv}>
                    <div className={css.LogoDiv}>
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className={css.AboutUsContent}>
                        <h1>Wisetech 服務範圍</h1>

                        <hr className={css.Line} />

                        <p>
                            Wisetech 提供全方位的資訊科技解決方案，服務涵蓋數據分析、網站建置、商業智慧 (BI)、客製化手機應用程式 (APP)。透過一鍵式操作，
                            迅速將原始資料轉換為行政或會計報表，並支援各種匯入格式及資料輸入方式。此外，我們亦提供銷售管理、採購及庫存管理、人事管理系統
                            （含員工資料庫及薪酬計算系統），以及基於雲端的線上 Excel 服務，實現資訊即時同步與多人協作，協助企業高效營運。
                        </p>
                    </div>
                </div>

                <div className={css.OurMissionDiv}>
                    <div className={css.MissionImage}>
                        <img src={leadership} alt="Mission" />
                    </div>
                    <div className={css.MissionText}>
                        <h1>我們的使命</h1>
                        <p>
                            提供最好的技術解決方案，幫助企業實現數字化轉型，提升效率和競爭力，並透過創新工具與智能系統，優化營運流程，加速決策制定，邁向智慧化經營。
                        </p>
                    </div>

                    <div className={css.MissionText}>
                        <h1>我們的宗旨</h1>
                        <p>
                            我們的宗旨是帶領客戶緊貼時代發展脈動，掌握科技趨勢，避免錯失商機。透過自動化與智能化工具，協助客戶以最少的人力處理重複性工作，專注於高價值決策與創新，提升整體營運效能。
                        </p>
                    </div>
                </div>

            </section>
        </>
    )

    return content
}

export default AboutUsPage