import css from '../../css/Public/ContactUs.module.css'
import contactImage from '../../global/ContactUS Image.png'

const ContactUsPage = () => {
    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.HeaderDiv}>
                    <h1>聯絡我們</h1>
                    <p>如有任何查詢或建議，歡迎隨時聯絡我們</p>
                    <p></p>

                    <hr className={css.Line} />

                    <div className={css.CardInfoList}>

                        <div className={css.EachContactItem}>
                            <div className={css.EachContactItemIcon}>
                                <i className="fi fi-sr-phone-call" />
                            </div>
                            <div className={css.EachContactItemText}>
                                <h1 className={css.SubTitle}>電話</h1>

                                <a href="tel:+85227173421">
                                    <p>(+852) 2717 3421</p>
                                    <p>(Thomas Ho)</p>
                                </a>
                            </div>
                        </div>

                        <div className={css.EachContactItem}>
                            <div className={css.EachContactItemIcon}>
                                <i className="fi fi-ss-envelope" />
                            </div>
                            <div className={css.EachContactItemText}>
                                <h1 className={css.SubTitle}>電郵</h1>

                                <a href="mailto:info@wisetechinterglobe.com.hk">
                                    <p>info@wisetechinterglobe.com.hk</p>
                                </a>
                            </div>
                        </div>

                        <div className={css.EachContactItem}>
                            <div className={css.EachContactItemIcon}>
                                <i className="fi fi-brands-whatsapp" />
                            </div>
                            <div className={css.EachContactItemText}>
                                <h1 className={css.SubTitle}>WhatsApp</h1>

                                <button className={css.WhatsappButton}>
                                    <a href="https://wa.me/+85261882830">
                                        <p>開始對話</p>
                                    </a>
                                </button>
                            </div>
                        </div>

                        <div className={css.EachContactItem}>
                            <div className={css.EachContactItemIcon}>
                                <i className="fi fi-sr-map-marker" />
                            </div>
                            <div className={css.EachContactItemText}>
                                <h1 className={css.SubTitle}>地址</h1>

                                <a href="https://maps.app.goo.gl/iq3nudD4zQh1TUsG9">
                                    <p>觀塘鴻圖道60號鴻福工廠大廈 13樓 3室</p>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className={css.ContactFormDiv}>
                    <div className={css.FormImageDiv}>
                        <img src={contactImage} alt="Contact Us" />
                    </div>

                    <div className={css.FormContentDiv}>
                        <h1>聯絡表格</h1>
                        <p>我們將在24小時內與你進一步聯絡</p>

                        <hr className={css.Line} />

                        <div className={css.FormEachInput}>
                            <label htmlFor="name">姓名</label>
                            <input type="text" name="name" placeholder="姓名" required />
                        </div>

                        <div className={css.FormEachInput}>
                            <label htmlFor="email">電郵地址</label>
                            <input type="text" name="email" placeholder="電郵地址" required />
                        </div>

                        <div className={css.FormEachInput}>
                            <label htmlFor="subject">主題</label>
                            <input type="text" name="subject" placeholder="主題" required />
                        </div>

                        <div className={css.FormEachInput}>
                            <label htmlFor="message">內容</label>
                            <textarea name="message" rows={5} placeholder="訊息" required />
                        </div>

                    </div>                    
                </div>

            </section>
        </>
    )

    return content
}

export default ContactUsPage