import css from '../../css/Public/HomePage.module.css'
import Banner from '../../global/PublicBanner.png'

import MS_Azure from '../../global/MS-Azure-logo.svg'
import shield from '../../global/shield.svg'
import Customization from '../../global/customization.svg'
import technical from '../../global/technical-support.svg'
import flash from '../../global/flash.svg'
import laptop from '../../global/laptop.svg'

import social from '../../global/social.svg'
import ios from '../../global/apple-logo.svg'
import windows from '../../global/windows.svg'
import website from '../../global/app.svg'

const HomePageV2 = () => {
    const content = (
        <>
            <section className={css.MainHomePage}>
                <div className={css.BannerDiv}>
                    <div className={css.BannerImage}>
                        <img src={Banner} alt="Banner" />
                    </div>
                    <div className={css.BannerText}>
                        <h1>我們的使命</h1>
                        <p>提供最好的技術解決方案，幫助企業實現數字化轉型，提升效率和競爭力</p>
                    </div>

                    <div className={css.BannerButtonDiv}>
                        <button className={css.BannerButton}>了解更多</button>
                    </div>
                </div>
            </section>

            <section className={css.contectpart1Div}>
                <div className={css.contectpart1Text}>
                    <h1>為什麼選擇我們</h1>
                    <p>我們的團隊由經驗豐富的專業人士組成，擁有多年的行業經驗和技術專長。我們致力於提供最優質的服務和支持，確保客戶的需求得到滿足。</p>
                    <p>我們的解決方案涵蓋了各個行業，包括零售、製造和飲食等。我們的技術專家將根據客戶的需求，提供量身定制的解決方案，幫助客戶實現業務目標。</p>
                    <p>我們的服務包括系統集成、數據分析、人工智能和雲計算等。我們的團隊將與客戶密切合作，確保項目的成功實施和交付。</p>
                    <p>我們的使命是幫助客戶實現數字化轉型，提升效率和競爭力。我們相信，通過技術的力量，我們可以幫助客戶實現更高的業務價值和增長。</p>
                </div>
            </section>

            <section className={css.EachPartADiv}>
                <div className={css.CardInfo}>
                    <div className={css.CardImage}>
                        <img src={shield} alt="數據安全性" style={{ display: 'flex', width: '80%', height: '80%', margin: 'auto' }}/>
                    </div>
                    <div className={css.CardText}>
                        <h1>數據安全性</h1>
                        <p>
                            我們的解決方案採用先進的加密技術與安全協議，確保客戶在資料傳輸和儲存過程中的安全與隱私。系統經過嚴謹的測試和驗證，
                            並定期進行安全審計與漏洞掃描，確保始終符合最新的安全標準。我們的團隊隨時為您提供支持與協助，並根據實際需求客製化最合適的安全方案。
                            透過彈性的資料存取與權限管理機制，只有經授權的使用者才能存取敏感資料，讓您在使用我們的解決方案時能夠高枕無憂。
                        </p>
                    </div>
                </div>
            </section>

            <section className={css.EachPartBDiv}>
                <div className={css.CardInfo}>
                    <div className={css.CardText}>
                        <h1>客製化設計</h1>
                        <p>
                            我們提供客製化設計服務，根據您的業務需求和特定要求，量身打造專屬解決方案。無論是界面設計、功能定制還是系統架構調整，我們的專業團隊都能提供靈活的設計方案，
                            確保最符合您需求的使用體驗。透過與客戶密切合作，我們能夠精準實現每一項設計細節，為您創造最大的價值。
                        </p>
                    </div>
                    <div className={css.CardImage}>
                        <img src={Customization} alt="Customization" style={{ display: 'flex', width: '80%', height: '80%', margin: 'auto' }} />
                    </div>
                </div>
            </section>

            <section className={css.EachPartADiv}>
                <div className={css.CardInfo}>
                    <div className={css.CardImage}>
                        <img src={technical} alt="專人跟進售後服務" style={{ display: 'flex', width: '80%', height: '80%', margin: 'auto' }} />
                    </div>

                    <div className={css.CardText}>
                        <h1>專人跟進售後服務</h1>
                        <p>
                            我們提供專業的售後服務，確保每位客戶在使用過程中得到持續的支持與協助。專人將全程跟進您的需求，並根據實際情況提供即時解決方案。無論是技術支援、
                            系統優化，或是任何其他問題，我們的團隊都會迅速回應，確保您的使用體驗無縫流暢。
                        </p>
                    </div>
                </div>
            </section>

            <section className={css.EachPartBDiv}>
                <div className={css.CardInfo}>
                    <div className={css.CardText}>
                        <h1>提升資料處理的速度 (提升約20倍)</h1>
                        <p>
                            我們的解決方案致力於提升資料處理的速度，通過先進的技術架構和優化算法，有效縮短資料處理時間，提升運行效率。
                            透過分布式計算與高效的資料處理流程，我們能夠大幅提高處理速度，同時確保資料的準確性和安全性。
                            無論處理大量數據或複雜運算，我們的系統都能快速響應，滿足業務需求。
                        </p>
                    </div>
                    <div className={css.CardImage}>
                        <img src={flash} alt="提升資料處理的速度" style={{ display: 'flex', width: '80%', height: '80%', margin: 'auto' }} />
                    </div>
                </div>
            </section>

            <section className={css.EachPartADiv}>
                <div className={css.CardInfo}>
                    <div className={css.CardImage}>
                        <img src={laptop} alt="數據視覺化顯示" style={{ display: 'flex', width: '80%', height: '80%', margin: 'auto' }} />
                    </div>

                    <div className={css.CardText}>
                        <h1>數據視覺化顯示</h1>
                        <p>
                            我們提供先進的數據視覺化顯示功能，幫助您將複雜的數據轉化為清晰、易懂的圖表和圖像。無論是實時數據監控、
                            趨勢分析，還是業務報告，我們的解決方案都能根據您的需求提供直觀的視覺呈現，讓您快速洞察關鍵資訊，做出明智決策。透過動態和互動式的視覺展示，提升資料分析的效率與準確性。
                        </p>
                    </div>
                </div>
            </section>

            <section className={css.SupportPlatformDiv}>
                <div className={css.SupportPlatformImage}>
                    <img src={social} alt="Andriod" />
                    <img src={ios} alt="IOS" />
                    <img src={windows} alt="Windows" />
                    <img src={website} alt="Website" />
                </div>
                <div className={css.SupportPlatformText}>
                    <h1>支援多平台</h1>
                    <p>
                        我們的解決方案全面支援多平台，包括 Android、iOS、Windows 和網站，確保您無論在任何設備上都能獲得一致且流暢的使用體驗。
                        無論是移動設備還是桌面平台，我們的系統都能實現無縫切換，滿足不同用戶的需求。通過跨平台的支持，
                        我們提供更高的靈活性和便捷性，幫助您在各種環境中高效工作。
                    </p>
                </div>
            </section>
        </>
    )

    return content
}

export default HomePageV2