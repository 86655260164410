import { useState } from 'react'
import UserListCmp from '../../../Components/C0211/HumanResources/UserList'
import EditUserCmp from '../../../Components/C0211/HumanResources/EditUser'
import InsertNewUserCmp from '../../../Components/C0211/HumanResources/InsertUser'
import css from '../../../css/C0211/UserlistPage.module.css'

const C0211UserListPage = () => {

    const [isuserid, setUserID] = useState<number | null>(null)


    function handleSearchAtTable() {
        const input = document.getElementById("SearchTableTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("UserListTable") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdStaffID = tr[i].getElementsByTagName("td")[0];
            const tdUsername = tr[i].getElementsByTagName("td")[1];
            if (tdStaffID || tdUsername) {
                const txtStaffIDValue = tdStaffID.textContent || tdStaffID.innerText;
                const txtUsernameValue = tdUsername.textContent || tdUsername.innerText;
                // Check if either Staff ID or Username matches the filter
                if (txtStaffIDValue.toUpperCase().indexOf(filter) > -1 ||
                    txtUsernameValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    const content = (
        <>
            <section className={css.TopSection}>
                <p>人力資源</p>
                {isuserid === null && (
                    <div className={css.SearchBar}>
                        <input type="text" id="SearchTableTxt" placeholder="搜尋" onKeyUp={handleSearchAtTable} />
                    </div>
                )}
            </section>

            {isuserid === null && (
                <UserListCmp setUserID={setUserID} />
            )}

            {isuserid === -1 && (
                <InsertNewUserCmp setUserID={setUserID} />
            )}

            {isuserid !== null && isuserid >= 0 && (
                <EditUserCmp
                    setUserID={setUserID}
                    isuserid={isuserid} />
            )}
        </>
    )

    return content
}

export default C0211UserListPage