import React, { useEffect, useState } from 'react'
import css from '../../../css/C0211/Storage.module.css'
import { Cookies } from 'react-cookie';
import { IC0211ProductWareHouse } from '../../../Interface/interface'
import axios from 'axios'

//import components
import ScanBarCodecmp from '../../../Components/ScanBarCode'
import StockProductFunctionList from '../../../Components/C0211/WareHouse/StockProductFunctionList'

import StockDefaultStatus from '../../../Components/C0211/WareHouse/StockDefaultStatus'
import StockLowStatus from '../../../Components/C0211/WareHouse/StockLowStatus'
import StockEnoughStatus from '../../../Components/C0211/WareHouse/StockEnoughStatus'
import StockRecordStatus from '../../../Components/C0211/WareHouse/StockRecordStatus'
import StockProductInOutRecord from '../../../Components/C0211/WareHouse/StockProductInOutRecord';
import StockProductDetail from '../../../Components/C0211/WareHouse/StockProductDetail';
import StockCreateTicket from '../../../Components/C0211/WareHouse/StockCreateTicket';

import AlertMessage from '../../../Components/LoadingBar/AlertMessage';
import DeafultLoadingBar from '../../../Components/LoadingBar/DefaultLoadingBar';

const cookies = new Cookies()

const StoragePage = () => {

    const [isdata, setData] = useState<IC0211ProductWareHouse[]>([])

    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');

    const [isshowbarcodereader, setShowBarCodeReader] = useState<Boolean>(false)
    const [isbarcodevalue, setBarCodeValue] = useState<String>("")

    const [isStockFunctionListShow, setStockFunctionListShow] = useState<Boolean>(false)
    const [isStockInOutRecordShow, setStockInOutRecordShow] = useState<Boolean>(false)
    const [isProductDetailShow, setProductDetailShow] = useState<Boolean>(false)
    const [isCreateTicketShow, setCreateTicketShow] = useState<Boolean>(false)
    const [istableMode, setTableMode] = useState<number>(1)

    const [isSelectProductDetail, setSelectProductDetail] = useState<IC0211ProductWareHouse>({
        Product_Sku_ID: -1,
        Product_Code: '',
        Product_Name: '',
        Product_Warehouse_Location: '',
        Product_Total_Stock: 0,
        Product_Min_Safe_Stock: 0,
        Product_Max_Safe_Stock: 0,
        Product_Barcode: '',
    })

    const [isEditProductSKUID, setEditProductSKUID] = useState<number>(-1)

    useEffect(() => {
        setLoading(true);
        getWareHouseData();
    }, [])

    useEffect(() => {
        if (isbarcodevalue !== "") {
            setShowBarCodeReader(false)
            // alert(isbarcodevalue)
            const getSKUID = isdata.find((item) => item.Product_Barcode === isbarcodevalue)
            if (getSKUID) {
                setEditProductSKUID(getSKUID.Product_Sku_ID)
                setProductDetailShow(true)
            }
            setBarCodeValue("")
        }
    }, [isbarcodevalue])

    useEffect(() => {
        if (isShowAlert === true) {
            setTimeout(() => {
                setShowAlert(false)
            }, 8000)
        }
    }, [isShowAlert])

    const handleDevleopFunction = () => {
        console.log('This function is under development')
        alert('This function is under development')
    }

    const getWareHouseData = async () => {
        const token = cookies.get('token')
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/c0502/product/GetProductWarehouseList`, {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (res.status === 200) {
                setData(res.data.ProductWarehouseList)
            }

        } catch (error) {
            console.error(error)
        } finally {
            console.log('Get Product Warehouse List Done')
            setLoading(false)
        }
    }

    const handleFunctionRouter = (funtionname: string, ProductSKUID: number) => {
        switch (funtionname) {
            case 'edit':
                handleEditProduct(ProductSKUID)
                break;
            case 'record':
                handleCheckInOutRecord(ProductSKUID)
                break;
            case 'detail':
                handleProductDetail(ProductSKUID)
                break;
            default:
                break;
        }
    }

    const handleEditProduct = (ProductSKUID: number) => {
        setEditProductSKUID(ProductSKUID)
        setStockFunctionListShow(true)
    }

    const handleCheckInOutRecord = (ProductSKUID: number) => {
        setEditProductSKUID(ProductSKUID)
        setStockInOutRecordShow(true)
    }

    const handleProductDetail = (ProductSKUID: number) => {
        setEditProductSKUID(ProductSKUID)
        setProductDetailShow(true)
    }

    const resetSearch = () => {
        const input = document.getElementById("SearchTableTxt") as HTMLInputElement;
        input.value = ""
    }

    function handleSearchAtTable() {
        const input = document.getElementById("SearchTableTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("StockTable") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdProductCode = tr[i].getElementsByTagName("td")[0];
            const tdProductName = tr[i].getElementsByTagName("td")[1];
            const tdWarehouse = tr[i].getElementsByTagName("td")[2];

            if (tdProductCode || tdProductName) {
                const txtCodeValue = tdProductCode.textContent || tdProductCode.innerText;
                const txtNameValue = tdProductName.textContent || tdProductName.innerText;
                const txtWarehouseValue = tdWarehouse.textContent || tdWarehouse.innerText;
                // Check if either Staff ID or Username matches the filter
                if (txtCodeValue.toUpperCase().indexOf(filter) > -1 ||
                    txtNameValue.toUpperCase().indexOf(filter) > -1 ||
                    txtWarehouseValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar isACID='C0211' />}
            {isshowbarcodereader && <ScanBarCodecmp setBarCodeValue={setBarCodeValue} setShowBarCodeReader={setShowBarCodeReader} />}
            {isStockFunctionListShow &&
                <StockProductFunctionList
                    ProductSKUID={isEditProductSKUID}
                    setStockFunctionListShow={setStockFunctionListShow}
                    setShowAlert={setShowAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                    setLoading={setLoading}
                />
            }
            {isCreateTicketShow &&
                <StockCreateTicket
                    setStockCreateTicketShow={setCreateTicketShow}
                    setShowAlert={setShowAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                    setLoading={setLoading}
                />}
            {isStockInOutRecordShow && <StockProductInOutRecord ProductSKUID={isEditProductSKUID} setStockInOutRecordShow={setStockInOutRecordShow} setLoading={setLoading} />}
            {isProductDetailShow && <StockProductDetail ProductSKUID={isEditProductSKUID} setProductDetailShow={setProductDetailShow} setLoading={setLoading} />}

            <section className={css.TopSection}>
                <p>倉存管理</p>

                <div className={css.SearchBar}>
                    {/* <input type="text" id="SearchUserTxt" placeholder="搜尋" onKeyUp={handleSearchUser} /> */}
                    <input type="text" id="SearchTableTxt" placeholder="搜尋" onKeyUp={handleSearchAtTable}/>
                    <i className="fi fi-bs-qr-scan" onClick={() => setShowBarCodeReader(true)} />
                </div>
            </section>

            <section className={css.FourInOneSection}>
                <div className={css.TotalUserDiv} onClick={() => {setTableMode(1); resetSearch()}}>
                    <div className={css.CardIcon} style={{ backgroundColor: '#FFB300' }}>
                        <i className="fi fi-rs-boxes" />
                    </div>
                    <div className={css.CardText}>
                        <p>貨品數量</p>
                        <p>{isdata.length}</p>
                    </div>
                </div>
                <div className={css.TotalUserDiv} onClick={() => {setTableMode(2); resetSearch()}}>
                    <div className={css.CardIcon} style={{ backgroundColor: '#77F9B4' }}>
                        <i className="fi fi-rs-box-open-full" />
                    </div>
                    <div className={css.CardText}>
                        <p>存貨充足商品數量</p>
                        <p>{isdata.filter((item) => item.Product_Total_Stock > item.Product_Min_Safe_Stock).length}</p>
                    </div>
                </div>
                <div className={css.TotalUserDiv} onClick={() => {setTableMode(3); resetSearch()}}>
                    <div className={css.CardIcon} style={{ backgroundColor: '#FF8080' }}>
                        <i className="fi fi-rs-box-open" />
                    </div>
                    <div className={css.CardText}>
                        <p>缺貨商品數量</p>
                        <p>{isdata.filter((item) => item.Product_Total_Stock <= item.Product_Min_Safe_Stock).length}</p>
                    </div>
                </div>

                <div className={css.TotalUserDiv} onClick={() => setTableMode(4)}>
                    <div className={css.CardIcon} style={{ backgroundColor: '#D8A2FF' }}>
                        <i className="fi fi-rs-to-do" />
                    </div>
                    <div className={css.CardText}>
                        <p>待處理記錄</p>
                        <p>0</p>
                    </div>
                </div>
            </section>

            <section className={css.MainSection}>
                <div className={css.AddNewOrderDiv} onClick={() => setCreateTicketShow(true)}>
                    <i className="fi fi-br-plus-hexagon" />
                    <p>建立新的訂單</p>
                </div>

                {istableMode === 1 && (
                    <StockDefaultStatus isdata={isdata} handleFunctionRouter={handleFunctionRouter} />
                )}

                {istableMode === 2 && (
                    <StockEnoughStatus isdata={isdata} handleFunctionRouter={handleFunctionRouter} />
                )}

                {istableMode === 3 && (
                    <StockLowStatus isdata={isdata} handleFunctionRouter={handleFunctionRouter} />
                )}

                {istableMode === 4 && (
                    <StockRecordStatus isdata={isdata} handleFunctionRouter={handleFunctionRouter} />
                )}


            </section>
        </>
    )

    return content
}

export default StoragePage