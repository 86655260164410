import css from '../../css/Public/Solution.module.css'
import DevProcess from '../../global/DevProcess.png'

const SolutionPage = () => {
    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.SolutionDiv}>
                    <div className={css.SolutionPageTitle}>
                        <h1>解決方案</h1>
                    </div>

                    <hr />

                    <div className={css.SolutionPageContent}>
                        <p>
                            我們的解決方案旨在幫助企業簡化流程、提高效率並降低成本。我們的專業團隊將根據您的需求提供量身定制的解決方案，確保您獲得最佳的結果。
                            無論您是需要自動化流程、數據分析還是系統集成，我們都能為您提供專業的支持和建議。請隨時聯繫我們以了解更多信息。
                        </p>
                    </div>

                    <div className={css.SolutionImage}>
                        <img src={DevProcess} alt="DevProcess" />
                    </div>
                </div>
            </section>

        </>
    )

    return content
}

export default SolutionPage